'use client';

import { ComponentPropsWithoutRef } from 'react';
import { useDisablePageBehindMenu } from '../pageHeader/mainMenu/MainMenu.client';
import { classes } from '@/uiPrimitives/base/classes.helpers';

export const A11YSkipLinksClient = ({
	className,
	...props
}: ComponentPropsWithoutRef<'ul'>) => {
	const {
		className: disablePageBehindMenuClassName,
		...disablePageBehindMenuProps
	} = useDisablePageBehindMenu();

	return (
		<ul
			{...props}
			{...disablePageBehindMenuProps}
			className={classes(className, disablePageBehindMenuClassName)}
		/>
	);
};
