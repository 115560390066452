'use client';

import { translateInline } from '@/globals/translate/translateInline';
import { IconLinkContent } from '@/uiComposites/interactive/IconLink';
import { closeIcon } from '@/uiIcons/closeIcon.css';
import { menuIcon } from '@/uiIcons/menuIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { focusBox } from '@/uiPrimitives/base/focus.css';
import { box } from '@/uiPrimitives/layout/box';
import { pile } from '@/uiPrimitives/layout/pile';
import { icon } from '@/uiPrimitives/media/icon';
import { ResponsiveMenuType, useMainMenuOpened } from './MainMenuContext';
import { mainMenuMeasurements, searchLinkText } from './mainMenuMeasurements';
import {
	hiddenForHamburgerMenu,
	hiddenForNavBarMenu,
	menuButton,
	submenu,
	submenuButton,
	submenuButtonClosed,
	submenuContent,
	menuItems,
	menu,
	menuItemsList,
	menuItemsScroll,
	submenuMore,
	submenuScroll,
	submenuFlyout,
	flyoutBackdrop,
	isOverflowingCSS,
} from './MainMenuStyle.css';
import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useId,
	useRef,
	useState,
} from 'react';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { textBlock, textStyle } from '@/uiPrimitives/typography/text';
import { occupy } from '@/uiPrimitives/layout/occupy';
import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { hidden } from '@/uiPrimitives/utility/display.css';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { MainMenuWebAPIData } from './MainMenu.types';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { position } from '@/uiPrimitives/layout/position';
import { useLastSearchURL } from '@/sections/search/useSearchHistory';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';
import { searchIcon } from '@/uiIcons/searchIcon.css';
import { config } from 'moduleAlias/config';
import { menuID } from '@/sections/a11ySkipLinks/A11YSkipLinks.config';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { pushMainMenuEvent } from '@/globals/analytics/events/pushMainMenuEvent';

const FlyoutIDContext = createContext<
	{ id: string; title: string } | undefined
>(undefined);

const useFlyoutID = () => {
	const context = useContext(FlyoutIDContext);
	if (context == null) {
		throw new Error('useFlyoutID must be used within a FlyoutIDProvider');
	}
	return context;
};

export const useDisablePageBehindMenu = () => {
	const [openedFlyout] = useMainMenuOpened();

	return {
		className: openedFlyout.mainMenu ? hiddenForHamburgerMenu : undefined,
		inert: openedFlyout.submenu != null ? ('' as never) : undefined,
	};
};

const useIsOverflowing = <
	ScrollElement extends HTMLElement,
	MeasureElement extends HTMLElement,
>() => {
	const scrollRef = useRef<ScrollElement>(null);
	const measureRef = useRef<MeasureElement>(null);
	const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

	useEffect(() => {
		const measureElement = measureRef.current;
		const scrollElement = scrollRef.current;

		if (measureElement == null) return;
		if (scrollElement == null) return;

		const observer = new IntersectionObserver(
			(entries) => {
				setIsOverflowing(!entries[0].isIntersecting);
			},
			{
				root: scrollElement,
				threshold: 1,
			},
		);
		observer.observe(measureElement);

		return () => {
			observer.disconnect();
		};
	}, []);

	return { scrollRef, measureRef, isOverflowing };
};

export const Menu = ({ children }: { children?: ReactNode }) => {
	const mainMenuID = useId();
	return (
		<FlyoutIDContext.Provider value={{ id: mainMenuID, title: 'mainMenu' }}>
			<nav
				tabIndex={-1}
				id={menuID}
				className={classes(
					menu,
					shelf({
						columnGap: mainMenuMeasurements.columnGap,
						alignItems: 'stretch',
						justifyContent: 'stretch',
						gridTemplateColumns: 'minmax(0, 1fr) auto',
					}),
				)}
			>
				{children}
			</nav>
		</FlyoutIDContext.Provider>
	);
};

export const MenuButton = () => {
	const { id } = useFlyoutID();
	const [openedFlyout, dispatchOpenedFlyout] = useMainMenuOpened();

	return (
		<button
			id={`button-${id}`}
			aria-expanded={openedFlyout.mainMenu}
			aria-controls={`flyout-${id}`}
			onClick={() => {
				if (openedFlyout.mainMenu) {
					dispatchOpenedFlyout({ type: 'closeMainMenu' });
					pushMainMenuEvent({ action: 'closeMenu' });
				} else {
					dispatchOpenedFlyout({ type: 'openMainMenu' });
					pushMainMenuEvent({ action: 'openMenu' });
				}
			}}
			className={classes(
				menuButton,
				hiddenForNavBarMenu,
				pile({ alignItems: 'center' }),
				focusBox,
				box({
					backgroundColor: openedFlyout.mainMenu
						? 'negative'
						: 'positiveProjectBrand',
					paddingInline: 'negative 0 | p',
				}),
				textStyle({ fontWeight: 'bold' }),
				placement({ justifySelf: 'start' }),
			)}
			{...addTestSelector('uiMenuButton')}
		>
			<IconLinkContent
				icon={
					<i
						aria-label={
							openedFlyout.mainMenu
								? translateInline({
										en: 'Close',
										nl: 'Sluit',
									})
								: translateInline({
										en: 'Open',
										nl: 'Open',
									})
						}
						className={classes(
							openedFlyout.mainMenu
								? icon({ icon: closeIcon, blockSize: '3 | h1' })
								: icon({ icon: menuIcon, blockSize: '2 | h2' }),
						)}
					/>
				}
				styling={{
					color: openedFlyout.mainMenu ? 'positive:noHover' : 'negative',
					iconSpace: mainMenuMeasurements.iconOccupy,
				}}
			>
				{translateInline({ en: 'Menu', nl: 'Menu' })}
			</IconLinkContent>
		</button>
	);
};

export const MenuItems = ({ children }: { children?: ReactNode }) => {
	const id = useFlyoutID();
	const [openedFlyout] = useMainMenuOpened();
	const { isOverflowing, measureRef, scrollRef } = useIsOverflowing<
		HTMLDivElement,
		HTMLUListElement
	>();

	return (
		<div className={classes(menuItems)} id={`flyout-${id}`}>
			<div
				ref={scrollRef}
				className={classes(
					menuItemsScroll,
					focusBox,
					!openedFlyout.mainMenu && hiddenForHamburgerMenu,
					isOverflowing && isOverflowingCSS,
				)}
				tabIndex={0}
			>
				<ul ref={measureRef} className={classes(menuItemsList)}>
					{children}
				</ul>
			</div>
		</div>
	);
};

export const Submenu = ({
	type,
	title,
	children,
}: {
	type: MainMenuWebAPIData['items'][number]['type'];
	title: string;
	children?: ReactNode;
}) => {
	const id = useId();
	return (
		<FlyoutIDContext.Provider value={{ id, title }}>
			<li className={classes(submenu, type === 'more' && submenuMore)}>
				{children}
			</li>
		</FlyoutIDContext.Provider>
	);
};

const getMenuType = (breakpoint: number) => {
	const menuType: ResponsiveMenuType = window.matchMedia(
		`(width > ${breakpoint}px)`,
	).matches
		? 'navBar'
		: 'hamburger';

	return menuType;
};

const useClickAndHover = ({
	breakpoint,
	isOpened,
	isAnyOpened,
	onClick,
	onHover,
}: {
	breakpoint: number;
	isOpened: boolean;
	isAnyOpened: boolean;
	onClick: (menuType: ResponsiveMenuType) => void;
	onHover: (menuType: ResponsiveMenuType) => void;
}) => {
	const timerRef = useRef<NodeJS.Timeout>();
	const protectHoverTimerRef = useRef<NodeJS.Timeout>();
	const protectHoverRef = useRef<boolean>(false);

	useEffect(() => {
		return () => {
			clearTimeout(timerRef.current);
			clearTimeout(protectHoverTimerRef.current);
		};
	}, []);

	return {
		onClick: () => {
			if (protectHoverRef.current && isOpened) return;

			protectHoverRef.current = false;
			clearTimeout(protectHoverTimerRef.current);
			clearTimeout(timerRef.current);
			const menuType = getMenuType(breakpoint);

			onClick(menuType);
		},
		onPointerLeave: (event: React.PointerEvent) => {
			if (event.pointerType !== 'mouse') return;

			clearTimeout(timerRef.current);
		},
		onPointerMove: (event: React.PointerEvent) => {
			if (event.pointerType !== 'mouse') return;

			clearTimeout(timerRef.current);

			const menuType = getMenuType(breakpoint);
			if (menuType === 'hamburger') return;

			if (!isAnyOpened) return;
			if (isOpened) return;

			const { currentTarget } = event;

			timerRef.current = setTimeout(() => {
				if (!currentTarget.matches(':hover')) return;

				const menuType = getMenuType(breakpoint);
				if (menuType === 'hamburger') return;

				onHover(menuType);

				protectHoverRef.current = true;
				clearTimeout(protectHoverTimerRef.current);
				protectHoverTimerRef.current = setTimeout(() => {
					protectHoverRef.current = false;
				}, 400);
			}, 20);
		},
	};
};

export const SubmenuButton = ({
	breakpoint,
	children,
}: {
	children: ReactNode;
	breakpoint: number;
}) => {
	const { id, title } = useFlyoutID();
	const [openedFlyout, dispatchOpenedFlyout] = useMainMenuOpened();

	const buttonEventProps = useClickAndHover({
		breakpoint,
		isOpened: openedFlyout.submenu === id,
		isAnyOpened: openedFlyout.submenu != null,
		onClick: (menuType) => {
			if (openedFlyout.submenu === id) {
				dispatchOpenedFlyout({
					type: 'closeSubmenu',
					submenuID: id,
					menuType,
				});
				pushMainMenuEvent({
					action: 'closeSubmenu',
					title,
				});
			} else {
				dispatchOpenedFlyout({
					type: 'openSubmenu',
					submenuID: id,
					menuType,
				});
				pushMainMenuEvent({ action: 'openSubmenu', title });
			}
		},
		onHover: (menuType) => {
			dispatchOpenedFlyout({
				type: 'openSubmenu',
				submenuID: id,
				menuType,
			});
			pushMainMenuEvent({ action: 'openSubmenu', title });
		},
	});

	const isOpened = openedFlyout.submenu === id;

	return (
		<button
			id={`button-${id}`}
			aria-expanded={isOpened}
			aria-controls={`flyout-${id}`}
			{...buttonEventProps}
			className={classes(
				submenuButton,
				!isOpened && submenuButtonClosed,
				shelf({
					alignContent: 'center',
					alignItems: 'start',
					gridTemplateColumns: 'minmax(0, 1fr) auto',
				}),
				focusBox,
			)}
			{...addTestSelector(`uiSubmenuButton-${children}`)}
		>
			<i
				className={classes(position({ position: 'absolute' }))}
				aria-label={
					isOpened
						? translateInline({
								en: 'Close',
								nl: 'Sluit',
							})
						: translateInline({
								en: 'Open',
								nl: 'Open',
							})
				}
			/>
			<div
				className={classes(textBlock({ fontWeight: 'bold', color: 'inherit' }))}
			>
				{children}
			</div>
			<div
				className={classes(
					occupy({
						blockSize: 'capSize',
						inlineSize: mainMenuMeasurements.chevronOccupy,
						alignItems: 'end',
						justifyItems: 'end',
					}),
				)}
			>
				<i
					className={classes(
						hiddenForNavBarMenu,
						icon({
							icon: chevronIcon,
							blockSize: 'capSize',
							fill: 'currentColor',
						}),
					)}
				/>
				<i
					className={classes(
						hiddenForHamburgerMenu,
						icon({
							icon: chevronIcon,
							blockSize: '1ex',
							adjustIcon: 'rotate90deg',
							flip: isOpened ? 'horizontal' : undefined,
							fill: 'currentColor',
						}),
					)}
				/>
			</div>
		</button>
	);
};

export const SubmenuContent = ({ children }: { children?: ReactNode }) => {
	const { id } = useFlyoutID();
	const [openedFlyout] = useMainMenuOpened();
	const isOpened = openedFlyout.submenu === id;
	const { isOverflowing, measureRef, scrollRef } = useIsOverflowing<
		HTMLDivElement,
		HTMLDivElement
	>();

	return (
		<div
			id={`flyout-${id}`}
			role="region"
			aria-labelledby={`button-${id}`}
			className={classes(submenuFlyout)}
		>
			<div
				ref={scrollRef}
				tabIndex={0}
				className={classes(
					submenuScroll,
					focusBox,
					box({ backgroundColor: 'negative' }),
					!isOpened && hidden,
					isOverflowing && isOverflowingCSS,
				)}
			>
				<div ref={measureRef} className={classes(submenuContent)}>
					{children}
				</div>
			</div>
		</div>
	);
};

export const SubmenuBackButton = () => {
	const { title } = useFlyoutID();
	const [openedFlyout, dispatchOpenedFlyout] = useMainMenuOpened();
	return (
		<button
			className={classes(
				hiddenForNavBarMenu,
				shelf({}),
				focusBox,
				box({
					paddingInline: 'negative 0 | p',
					paddingBlockStart: 'negative 4 | betweenListItems',
					paddingBlockEnd: 'negative 1 | h3',
					backgroundColor: 'negative',
				}),
				position({ position: 'sticky', insetBlockStart: '0' }),
				textStyle({ color: 'positiveBlue' }),
			)}
			onClick={() => {
				if (openedFlyout.submenu == null) return;

				dispatchOpenedFlyout({
					type: 'closeSubmenu',
					submenuID: openedFlyout.submenu,
					menuType: 'hamburger',
				});
				pushMainMenuEvent({
					action: 'closeSubmenu',
					title,
				});
			}}
			{...addTestSelector('uiSubmenuBackButton')}
		>
			<span
				className={classes(
					occupy({
						inlineSize: indentSize,
						blockSize: 'capSize',
						justifyItems: 'start',
					}),
				)}
			>
				<i
					className={classes(
						icon({
							icon: chevronIcon,
							blockSize: 'capSize',
							adjustIcon: 'flipHorizontal',
						}),
					)}
				/>
			</span>
			<span className={classes(textBlock({}))}>
				{translateInline({
					en: 'Main menu',
					nl: 'Hoofdmenu',
				})}
			</span>
		</button>
	);
};

export const SearchLink = () => {
	const lastSearchURL = useLastSearchURL() ?? config.searchPath;

	return (
		<DOPLink
			href={lastSearchURL}
			data-link-type={'openSearch'}
			className={classes(
				pile({ alignItems: 'center' }),
				textStyle({ fontWeight: 'bold' }),
				box({
					paddingInline: 'negative 0 | p',
				}),
				focusBox,
			)}
			{...addTestSelector('uiMainMenuLink-Search')}
		>
			<IconLinkContent
				icon={
					<i
						className={classes(
							icon({
								icon: searchIcon,
								blockSize: '3 | h1',
							}),
						)}
					/>
				}
				styling={{
					color: 'negative',
					iconSpace: mainMenuMeasurements.iconOccupy,
				}}
			>
				{searchLinkText}
			</IconLinkContent>
		</DOPLink>
	);
};

export const FlyoutBackdrop = ({ breakpoint }: { breakpoint: number }) => {
	const [openedFlyout, dispatchOpenedFlyout] = useMainMenuOpened();

	return (
		<div
			className={classes(
				flyoutBackdrop,
				zIndex({ zIndex: 'flyoutBackdrop' }),
				hiddenForHamburgerMenu,
				openedFlyout.submenu == null && hiddenForNavBarMenu,
			)}
			onClick={() => {
				const menuType = getMenuType(breakpoint);

				if (menuType === 'hamburger') return;

				dispatchOpenedFlyout({ type: 'closeMainMenu' });

				if (openedFlyout.submenu != null) {
					pushMainMenuEvent({
						action: 'closeMenu',
					});
				} else {
					pushMainMenuEvent({ action: 'closeMenu' });
				}
			}}
		/>
	);
};
