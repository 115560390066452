import { Space } from '@/uiPrimitives/layout/space.definitions';
import { CapSize } from '@/uiPrimitives/typography/typography.definitions';
import { FontVariations } from '@/uiPrimitives/typography/characterWidthMap.definitions';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';
import { translateInline } from '@/globals/translate/translateInline';

export type MainMenuMeasurements = {
	columnGap: Space;
	chevronOccupy: Space;
	iconOccupy: Space;
	capSize: CapSize;
	fontVariation: FontVariations;
};

const columnGap = '5 | betweenParagraphs' satisfies Space;
const chevronOccupy = '3 | h1' satisfies Space;
const capSize = '0 | p' satisfies CapSize;
const fontVariation = 'bold' satisfies FontVariations;
const iconOccupy = indentSize;

export const mainMenuMeasurements = {
	columnGap,
	chevronOccupy,
	capSize,
	fontVariation,
	iconOccupy,
} as const satisfies MainMenuMeasurements;

export const searchLinkText = translateInline({
	en: 'Search',
	nl: 'Zoeken',
});
