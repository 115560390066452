'use client';

import './GoogleReCaptchaProvider.css';
import React from 'react';
import {
	useGoogleReCaptcha as useReCaptcha,
	GoogleReCaptchaProvider as Provider,
} from 'react-google-recaptcha-v3';

import environmentConfig from 'moduleAlias/environmentConfig';
import { awaitAnimationFrame } from '@dop/shared/helpers/awaitAnimationFrame';

const fakeExecute = async () => 'NO.NO.NO.TOKEN';

export const useGoogleReCaptcha = () => {
	const { executeRecaptcha } = useReCaptcha();

	if (process.env.NODE_ENV === 'test') {
		return fakeExecute;
	}

	if (
		!['productie'].includes(environmentConfig.env) &&
		typeof window !== 'undefined' &&
		window.Cypress
	) {
		// During Cypress testing, allow for quick testing;
		return fakeExecute;
	}

	return async (action?: string) => {
		await awaitAnimationFrame();
		return await executeRecaptcha?.(action);
	};
};

export const GoogleReCaptchaProvider = ({
	children,
}: {
	children: React.ReactNode;
}): React.ReactElement | null => {
	return (
		<Provider reCaptchaKey={environmentConfig.recaptchaKey}>
			{children}
		</Provider>
	);
};
