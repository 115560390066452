import { config } from 'moduleAlias/config';

const lang = config.languageCode;

/**
 * Use this for one of translations. Especially if you need it to run on the client.
 */
export const translateInline = (templates: { en: string; nl: string }) => {
	return templates[lang];
};
