import { pushEvent } from '../pushEvent';

export const pushMainMenuEvent = ({
	action,
	title,
}:
	| {
			action: 'openMenu' | 'closeMenu';
			title?: never;
	  }
	| { action: 'openSubmenu' | 'closeSubmenu'; title: string }) => {
	pushEvent({
		events: {
			category: 'interaction.mainMenu',
			action,
			label: title,
		},
	});
};
