// Routes can be used by package express, package match-path or compilePathname
import { config } from 'moduleAlias/config';

// === Node-api === /

export const searchRoutesApi = [
	String.raw`/_search/facet/:facetSlug/term/:term/page/:page`,
	String.raw`/_search/more/facet/:facetSlug/term/:term/page/:page`,
];

// === OP === //

export const searchRoutesOP = [
	String.raw`/zoeken/filter/:facetSlug/zoekterm/:term/pagina/:page(\d+)/`,
	String.raw`/zoeken/filter/:facetSlug/zoekterm/:term/`,
	String.raw`/zoeken/zoekterm/:term/pagina/:page(\d+)/`,
	String.raw`/zoeken/zoekterm/:term/`,
];

// === BGOV === //

export const searchRoutesBGOV = [
	String.raw`/search/filter/:facetSlug/query/:term/page/:page(\d+)/`,
	String.raw`/search/filter/:facetSlug/query/:term/`,
	String.raw`/search/query/:term/page/:page(\d+)/`,
	String.raw`/search/query/:term/`,
];

const projectSearchRoutesMapping = {
	op: searchRoutesOP,
	bgov: searchRoutesBGOV,
} as const;

// Based on config, returns either OP or BGOV search routes
export const getProjectSearchRoutes = () => {
	if (!(config.project in projectSearchRoutesMapping))
		throw new Error(
			`Could not look up search routes for project ${config.project}`,
		);

	return projectSearchRoutesMapping[config.project];
};
