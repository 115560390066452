'use client';
import { openedMenu, openedSearch } from './PageHeader.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { icon } from '@/uiPrimitives/media/icon';
import { menuIcon } from '@/uiIcons/menuIcon.css';
import { searchIcon } from '@/uiIcons/searchIcon.css';
import { closeIcon } from '@/uiIcons/closeIcon.css';
import { ReturnLinksClient } from './ReturnLinks.client';
import { useLastSearchURL } from '../search/useSearchHistory';
import { Translation } from '@/globals/translate/translations';
import { PropsWithChildren, ReactNode } from 'react';
import { focusBox } from '@/uiPrimitives/base/focus.css';
import { box } from '@/uiPrimitives/layout/box';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';
import { occupy } from '@/uiPrimitives/layout/occupy';
import { textBlock, textStyle } from '@/uiPrimitives/typography/text';
import { pageGridItem } from '@/uiPrimitives/layout/pageGrid/pageGridItem';
import { menuID } from '../a11ySkipLinks/A11YSkipLinks.config';

export const LinkContent = ({
	children,
	iconSlot,
}: PropsWithChildren<{ iconSlot: ReactNode }>) => {
	return (
		<span
			className={classes(
				focusBox,
				box({ paddingInline: 'negative -2 | smaller' }),
				shelf({ alignItems: 'center' }),
			)}
		>
			<span
				className={classes(
					occupy({
						inlineSize: indentSize,
						blockSize: 'capSize',
						justifyItems: 'start',
					}),
				)}
			>
				{iconSlot}
			</span>
			<span className={classes(textBlock({}))}>{children}</span>
		</span>
	);
};

export const PageHeaderMenuClient = ({
	menuText,
	closeText,
	openText,
	searchText,
	menuPath,
	searchPath,
}: {
	menuText: Translation<'Menu'>;
	closeText: Translation<'Close (imperative)'>;
	openText: Translation<'Open'>;
	searchText: Translation<'Search'>;
	menuPath: string;
	searchPath: string;
}) => {
	const lastSearchURL = useLastSearchURL() ?? searchPath;

	return (
		<ReturnLinksClient
			menu={{
				href: menuPath,
				id: menuID,
				className: classes(
					pageGridItem({
						gridRowStart: '1',
						gridColumnStart: 'page-start',
						gridColumnEnd: 'span 1',

						justifySelf: 'start',
					}),
					shelf({}),
					textStyle({ fontWeight: 'bold' }),
				),
				providerTypes: ['browse', 'menu', 'themaMenu'],
				closedProps: {
					'data-link-type': 'openMenu',
					'aria-label': `${openText} ${menuText}`,
				},
				closedSlot: (
					<LinkContent
						iconSlot={
							<i
								className={classes(
									icon({ icon: menuIcon, blockSize: '2 | h2' }),
								)}
							/>
						}
					>
						{menuText}
					</LinkContent>
				),
				openedProps: {
					'data-link-type': 'closeMenu',
					className: openedMenu,
					'aria-label': `${closeText} ${menuText}`,
				},
				openedSlot: (
					<LinkContent
						iconSlot={
							<i
								className={classes(
									icon({ icon: closeIcon, blockSize: '3 | h1' }),
								)}
							/>
						}
					>
						{closeText}
					</LinkContent>
				),
			}}
			search={{
				href: lastSearchURL,
				className: classes(
					pageGridItem({
						gridRowStart: '1',
						gridColumnStart: 'span 1',
						gridColumnEnd: 'page-end',
						justifySelf: 'end',
					}),
					shelf({}),
					textStyle({ fontWeight: 'bold' }),
				),
				providerTypes: ['search'],
				closedProps: {
					'data-link-type': 'openSearch',
					'aria-label': `${openText} ${searchText}`,
				},
				closedSlot: (
					<LinkContent
						iconSlot={
							<i
								className={classes(
									icon({ icon: searchIcon, blockSize: '3 | h1' }),
								)}
							/>
						}
					>
						{searchText}
					</LinkContent>
				),
				openedProps: {
					'data-link-type': 'closeSearch',
					className: openedSearch,
					'aria-label': `${closeText} ${searchText}`,
				},
				openedSlot: (
					<LinkContent
						iconSlot={
							<i
								className={classes(
									icon({ icon: closeIcon, blockSize: '3 | h1' }),
								)}
							/>
						}
					>
						{closeText}
					</LinkContent>
				),
			}}
		/>
	);
};
