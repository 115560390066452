'use client';

import { loadingIndicatorCSS, loadingOverlayCSS } from './LoadingIndicator.css';
import { useIsNavigating } from '@/globals/routing/DOPRouter';

export const LoadingIndicator = () => {
	const isNavigating = useIsNavigating();

	if (!isNavigating) return null;

	return (
		<>
			<i className={loadingOverlayCSS} />
			<i className={loadingIndicatorCSS} />
		</>
	);
};
